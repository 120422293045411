import { PowerToConnectorsGroupMap } from 'src/_shared/types/charging'
import { OmniTariff } from 'src/_shared/types/omni'
import { formatCurrencyValue } from 'src/_shared/utils/string'

/**
 * Display the connector's active tariff pricing,
 * which is either `price_per_kwh` (higher priority) or `price_per_min`.
 * @param {OmniTariff | undefined} tariff The tariff object containing pricing details.
 * @returns {string} The formatted tariff price value with currency prefix, and the unit
 */
export const getConnectorTariffPriceValue = (tariff?: OmniTariff): string => {
	const pricePerKwh = tariff?.price_per_kwh?.incl_vat ?? 0
	const pricePerMin = tariff?.price_per_min?.incl_vat ?? 0
	if (pricePerKwh > 0 || pricePerMin === 0) {
		return `${formatCurrencyValue(pricePerKwh, tariff?.currency)}/kWh`
	}
	return `${formatCurrencyValue(pricePerMin, tariff?.currency)}/min`
}

/**
 * Checks if all connectors within each group do not exceed the specified maximum length.
 * @param {PowerToConnectorsGroupMap} groupedConnectors An object where the keys are group identifiers and the values are objects containing connector arrays.
 * @param {number} maxLength The maximum allowed length for the connectors array in each group.
 * @returns {boolean} A boolean indicating whether all connector arrays are within the specified maximum length.
 */
const areAllConnectorsWithinLength = (
	groupedConnectors: PowerToConnectorsGroupMap,
	maxLength: number
): boolean => {
	return Object.entries(groupedConnectors).every(
		([, value]): boolean => value.connectors.length <= maxLength
	)
}

/**
 * Updates the `groupedConnectors` object by setting the `isInitiallyCollapsed` property to `false` for each connector group.
 * @param {PowerToConnectorsGroupMap} groupedConnectors An object where the keys are connector group identifiers and the values are connector group details.
 * @returns {PowerToConnectorsGroupMap} A new `PowerToConnectorsGroupMap` object with the `isInitiallyCollapsed` property set to `false` for each group.
 */
const mapToExpandedPowerToConnectorsGroupMap = (
	groupedConnectors: PowerToConnectorsGroupMap
): PowerToConnectorsGroupMap => {
	const newPowerToConnectorsGroupMap: PowerToConnectorsGroupMap = {}
	Object.entries(groupedConnectors).forEach(([key, value]) => {
		newPowerToConnectorsGroupMap[key] = { ...value, isInitiallyCollapsed: false }
	})
	return newPowerToConnectorsGroupMap
}

/**
 * Groups connectors and determines whether they should be collapsed or expanded.
 * There are two cases where all groups will be expanded:
 * 1. There is only one group.
 * 2. There are two groups and all connectors have a length less than or equal to 10.
 * @param {PowerToConnectorsGroupMap} groupedConnectors The connectors grouped by some criteria.
 * @returns {PowerToConnectorsGroupMap} The grouped connectors, either expanded or collapsed.
 */
export const getPowerToConnectorsGroupMapWithCollapsed = (
	groupedConnectors: PowerToConnectorsGroupMap
): PowerToConnectorsGroupMap => {
	const length = Object.keys(groupedConnectors).length
	if (length === 1 || (length === 2 && areAllConnectorsWithinLength(groupedConnectors, 10))) {
		return mapToExpandedPowerToConnectorsGroupMap(groupedConnectors)
	}
	// Otherwise, collapse all groups, by default `isInitiallyCollapsed` is true
	return groupedConnectors
}
