import { SVGProps, memo } from 'react'

const PhoneCameraIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 49 88',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	const phoneFill = '#82868C'
	const phoneBottomFill = '#D6D7D7'
	const cameraRing = '#FAFAFA'
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<rect y="88" width="88" height="49" rx="6" transform="rotate(-90 0 88)" fill={phoneFill} />
			<rect x="15" y="83" width="19" height="2" rx="1" fill={phoneBottomFill} />
			<circle cx="13" cy="12" r="6.5" stroke={cameraRing} strokeWidth="3" />
			<circle cx="13" cy="12" r="3" />
		</svg>
	)
}

const MemoisedPhoneCameraIcon = memo(PhoneCameraIcon)

export default MemoisedPhoneCameraIcon
