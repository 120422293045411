export enum AppMode {
	/**
	 * In this mode:
	 * - The `LandingScreen` is not accessible.
	 * - Users have to login to charge.
	 */
	Default = 'default',
	/**
	 * In this mode:
	 * - The `LandingScreen` is not accessible.
	 * - Users have to login via a Single Sign-On (SSO) Token provided externally via query parameter.
	 */
	Sso = 'sso',
	/**
	 * In this mode:
	 * - Only the `LandingScreen`, `CheckIn` screens, and `ChargingScreen` are accessible.
	 * - Users do not have to login to charge. Hence, the `ChargingScreen` flow will be slightly different.
	 */
	Transient = 'transient'
}

export enum Brand {
	/**
	 * App Mode: `default`
	 */
	Beep = 'beep',
	/**
	 * App Mode: `default`
	 */
	Demo = 'demo',
	/**
	 * App Mode: `default`
	 */
	Elecca = 'elecca',
	/**
	 * App Mode: `default`
	 */
	Evfy = 'evfy',
	/**
	 * App Mode: `sso`
	 */
	Evme = 'evme',
	/**
	 * App Mode: `default`
	 */
	Grab = 'grab',
	/**
	 * App Mode: `transient`
	 */
	Kineta = 'kineta',
	/**
	 * App Mode: `default`
	 */
	KuehneNagelBusways = 'knbev',
	/**
	 * App Mode: `default`
	 */
	QuantumMobility = 'quantum',
	/**
	 * App Mode: `transient`
	 */
	SpGroup = 'sp',
	/**
	 * App Mode: `sso`
	 */
	TouchNGo = 'tng',
	/**
	 * App Mode: `default`
	 */
	Tribecar = 'tribecar',
	/**
	 * App Mode: `default`
	 */
	UpsBusways = 'upsbev'
}

export enum Language {
	English = 'en',
	Thai = 'th'
}
