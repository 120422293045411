import { useCallback, useEffect, useRef } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import LocationListItem from 'src/_shared/components/LocationListItem'
import Spinner from 'src/_shared/components/Spinner'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni/location'

interface LocationsListControllerProps {
	locations: OmniLocationNearby[]
	isFetchingNextPage: boolean
	onFetchNextPageCallback?: () => void
}

const BOTTOM_THRESHOLD = 650 // Pixels

const LocationListController = ({
	locations,
	isFetchingNextPage,
	onFetchNextPageCallback: handleFetchNextPageCallback
}: LocationsListControllerProps): JSX.Element => {
	const virtuoso = useRef<VirtuosoHandle | null>(null)

	const navigate = useRouterNavigate()

	const renderRow = useCallback(
		(index: number): JSX.Element => {
			const handleLocationListItemClick = (location: OmniLocation): void => {
				if (location.uid) {
					window.location.hash = location.uid // For scroll-back
					navigate([ScreenRoutePath.Location, location.uid])
				}
			}
			return (
				<LocationListItem
					key={index}
					locationNearby={locations[index]}
					onLocationListItemClick={handleLocationListItemClick}
				/>
			)
		},
		[locations, navigate]
	)

	/**
	 * Scrolls the user into the last entered Location List Item.
	 */
	useEffect(
		(): void => {
			if (window.location.hash) {
				const locationUid = window.location.hash.slice(1)
				const index = locations.findIndex(({ location }): boolean => {
					return location?.uid === locationUid
				})
				if (index >= 0) {
					virtuoso.current?.scrollToIndex(index)
				}
				// Remove previous hash value
				window.history.replaceState(null, '', window.location.href.split('#')[0])
			}
		},
		// To only trigger when component is mounted and without listening to changes in `locations`.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<div className="w-full">
			<Virtuoso
				atBottomThreshold={BOTTOM_THRESHOLD}
				data={locations}
				ref={virtuoso}
				endReached={handleFetchNextPageCallback}
				itemContent={renderRow}
				components={{
					Footer: (): JSX.Element => {
						if (isFetchingNextPage) {
							return (
								<div className="flex items-center justify-center pb-12 pt-4">
									<Spinner className="h-4 w-4" />
								</div>
							)
						}
						return <div className="h-12" />
					}
				}}
			/>
		</div>
	)
}

export default LocationListController
