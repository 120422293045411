import { useEffect } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'

import { Coordinates } from '../types/location'

const WATCH_POSITION_TIMEOUT = 5000 // 5 Seconds

const USER_COORDINATES_KEY = 'userCoordinates'

interface UserCoordinates {
	coordinates: Coordinates | null
	/**
	 * If `null`, then user has yet to allow or block the permission request.
	 */
	isPermissionGranted: boolean | null
}

/**
 * Requests for location permissions and watch the user's position if permission is granted.
 * The permission request prompt is only done once. Should the user denies permissions, they
 * have to manually enable it themselves.
 */
export const useUserCoordinates = (): UserCoordinates => {
	// The user coordinates are stored in the session storage. It is updated whenever the user's position changes.
	const [userCoordinates, setUserCoordinates] =
		useSessionStorage<UserCoordinates>(USER_COORDINATES_KEY)

	useEffect((): (() => void) => {
		const watchId = navigator.geolocation.watchPosition(
			(position): void => {
				setUserCoordinates({
					coordinates: {
						// Note: Directly access both `latitude` and `longitude` to ensure that the
						// numeric coordinates provided are saved directly into session storage, regardless
						// of browser implementation of `GeolocationCoordinates`. Rounded to 3 d.p. to reduce
						// excessive re-rendering.
						latitude: Number(position.coords.latitude.toFixed(3)),
						longitude: Number(position.coords.longitude.toFixed(3))
					},
					isPermissionGranted: true
				})
			},
			(error): void => {
				/**
				 * Handle returned error code.
				 * @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
				 */
				switch (error.code) {
					// `PERMISSION_DENIED`
					case 1: {
						console.error('[useUserCoordinates] Permission Denied', error)
						setUserCoordinates({
							coordinates: null,
							isPermissionGranted: false
						})
						return
					}
					// `POSITION_UNAVAILABLE`
					case 2: {
						console.error('[useUserCoordinates] Position Unavailable', error)
						return
					}
					// `TIMEOUT`
					case 3: {
						console.error('[useUserCoordinates] Position Acquisition Timed Out', error)
						return
					}
					default: {
						console.error('[useUserCoordinates]', error)
					}
				}
			},
			{
				maximumAge: 0,
				timeout: WATCH_POSITION_TIMEOUT
			}
		)
		return (): void => {
			navigator.geolocation.clearWatch(watchId)
		}
	}, [userCoordinates, setUserCoordinates])

	return {
		coordinates: userCoordinates?.coordinates ?? null,
		isPermissionGranted: userCoordinates?.isPermissionGranted ?? null
	}
}
