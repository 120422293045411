import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import CarparkRatesModal from 'src/_shared/components/CarparkRatesModal'
import ChargerTag from 'src/_shared/components/ChargerTag'
import Error404Notice from 'src/_shared/components/Error404Notice'
import FavouriteErrorModal from 'src/_shared/components/FavouriteErrorModal'
import LocationHeaderActions from 'src/_shared/components/LocationHeaderActions'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import DirectionsIcon from 'src/_shared/components/_icons/DirectionsIcon'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import LocationDetailsClockIcon from 'src/_shared/components/_icons/LocationDetailsClockIcon'
import LocationDetailsPinIcon from 'src/_shared/components/_icons/LocationDetailsPinIcon'
import QrScannerIcon from 'src/_shared/components/_icons/QrScannerIcon'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useIsUserSubscribed } from 'src/_shared/hooks/useIsUserSubscribed'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { useLocationQuery } from 'src/_shared/queries/locations'
import { ConnectorsGroupedByPower, PowerToConnectorsGroupMap } from 'src/_shared/types/charging'
import { sortEvsesByPower } from 'src/_shared/utils/charging'
import { formatDistanceDisplay } from 'src/_shared/utils/format'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'
import { shareLocationUrl } from 'src/_shared/utils/redirect'
import ConnectorsListView from 'src/screens/LocationDetailsScreen/components/ConnectorsListView'
import { getPowerToConnectorsGroupMapWithCollapsed } from 'src/screens/LocationDetailsScreen/utils'

// 10 Seconds
const DEFAULT_REFETCH_INTERVAL = 10000

interface LocationDetailsScreenRouteParams {
	locationUid: string
}

const LocationDetailsScreen = (): JSX.Element => {
	const navigate = useNavigate()

	const [favouriteActionErrorMessage, setFavouriteActionErrorMessage] = useState<string | null>(
		null
	)

	const { locationUid } = useParams() as unknown as LocationDetailsScreenRouteParams

	const { coordinates } = useUserCoordinates()

	const { brandData } = useStrapiContext()

	const { isAuthenticated } = useAuthContext()

	const [isCarparkModalOpen, setCarparkModalOpen] = useState<boolean>(false)

	const { data: location = null, status: locationQueryStatus } = useLocationQuery(
		{ locationUid },
		{
			enabled: !!locationUid,
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			refetchIntervalInBackground: true,
			refetchOnMount: 'always'
		}
	)

	const isUserSubscribed = useIsUserSubscribed(location?.entity_code)

	const distance = useMemo((): string | null => {
		if (!!location && coordinates && location.coordinates) {
			const { latitude = '0', longitude = '0' } = location.coordinates
			const distanceFromUser = getDistanceBetweenCoordinates(coordinates, {
				latitude: Number(latitude),
				longitude: Number(longitude)
			})
			return formatDistanceDisplay(distanceFromUser)
		}
		return null
	}, [coordinates, location])

	const cposWithQrSupport = useMemo((): string[] => {
		return (
			brandData?.attributes.configuration?.checkInCpoOptions
				?.filter(({ disableScanQrButton }): boolean => !disableScanQrButton)
				.map((option): string => option.entityCode ?? '') ?? []
		)
	}, [brandData?.attributes.configuration?.checkInCpoOptions])

	const groupedConnectors = useMemo((): PowerToConnectorsGroupMap => {
		return getPowerToConnectorsGroupMapWithCollapsed(sortEvsesByPower(location?.evses ?? []))
	}, [location?.evses])

	const handleCarParkRatesModalOpen = useCallback((): void => {
		setCarparkModalOpen(true)
	}, [])

	const handleCarParkRatesModalClose = useCallback((): void => {
		setCarparkModalOpen(false)
	}, [])

	const handleCloseFavouriteErrorModal = useCallback((): void => {
		setFavouriteActionErrorMessage(null)
	}, [])

	const handleFavouriteActionError = useCallback((message: string): void => {
		setFavouriteActionErrorMessage(message)
	}, [])

	return (
		<>
			<ScreenContainer
				topBarProps={{
					centerRender: location && (
						<img src={location.operator?.logo} className="max-h-7 w-auto object-contain" />
					)
				}}
				contentViewProps={{ className: 'px-5 pt-6' }}
				hideBottomBar
			>
				{locationQueryStatus === 'pending' && (
					<div className="flex flex-grow flex-col items-center justify-center">
						<Spinner />
					</div>
				)}
				{(locationQueryStatus === 'error' ||
					(locationQueryStatus === 'success' && location === null)) && <Error404Notice />}
				{locationQueryStatus === 'success' && location !== null && (
					<div className="flex flex-grow flex-col">
						{/* Heading */}
						<div className="flex flex-row justify-between space-x-1">
							<div className="flex flex-col">
								<h1
									className="mb-0.5 flex-grow items-center space-x-2 text-typography-primary"
									data-testid="lds-location-details-name"
								>
									<span>{location.name ?? '-'}</span>
									{isUserSubscribed && isAuthenticated && (
										<ChargerTag
											className="truncate border border-orange-400 bg-orange-400 bg-opacity-15 px-1 align-middle text-orange-400"
											value="Subscribed"
										/>
									)}
								</h1>
								<p
									className="body-1-normal mb-1.5 text-typography-secondary"
									data-testid="lds-location-details-address"
								>
									{location.address ?? '-'}
								</p>
							</div>
							<LocationHeaderActions
								location={location}
								handleCarParkRatesModalOpen={handleCarParkRatesModalOpen}
								handleFavouriteActionError={handleFavouriteActionError}
							/>
						</div>
						{/* Sub-Heading */}
						<div className="mb-5 flex flex-row items-center space-x-2.5">
							{distance !== null && (
								<div
									className="flex items-center space-x-1"
									data-testid="lds-location-details-24-hours"
								>
									<LocationDetailsPinIcon className="h-4 w-4 text-typography-tertiary" />
									<p className="body-1-normal text-typography-secondary">{distance}</p>
								</div>
							)}
							{/* 24 Hours */}
							{location.opening_times?.twentyfourseven === true && (
								<div
									className="flex items-center space-x-1"
									data-testid="lds-location-details-24-hours"
								>
									<LocationDetailsClockIcon className="h-3.5 w-3.5 text-typography-tertiary" />
									<p className="body-1-normal text-typography-secondary">24 Hours</p>
								</div>
							)}
							{/* Available Indicator */}
							<div className="flex flex-row items-center space-x-1">
								<div className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-success-100">
									<LightningBoltFilledIcon className="h-2 w-2 text-success-400" />
								</div>
								<p className="body-1-medium text-success-400">Available</p>
							</div>
							{/* Unavailable Indicator */}
							<div className="flex flex-row items-center space-x-1">
								<div className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-error-100">
									<LightningBoltFilledIcon className="h-2 w-2 text-error-300" />
								</div>
								<p className="body-1-medium text-error-300">Unavailable</p>
							</div>
						</div>
						{/* Grouped Listing */}
						<div className="flex flex-grow flex-col">
							{Array.from(Object.entries(groupedConnectors)).map(
								([key, value]: [string, ConnectorsGroupedByPower]): JSX.Element => {
									return (
										<ConnectorsListView
											key={key}
											className="mb-5"
											locationUid={location.uid}
											cpoEntityCode={location.entity_code}
											powerRating={value.powerRating}
											powerType={value.powerType}
											connectors={value.connectors}
											isInitiallyCollapsed={value.isInitiallyCollapsed}
										/>
									)
								}
							)}
						</div>

						{/* Footer */}
						<div className="sticky bottom-0 flex items-center space-x-2 bg-gradient-to-b from-[#FFFFFFEE] to-white py-6">
							{/* Directions and Scan QR Buttons */}
							{((): JSX.Element => {
								const hasQrCodeSupport =
									location.entity_code && cposWithQrSupport.includes(location.entity_code)

								const directionButton = (
									<Button
										className="flex-1"
										variant={hasQrCodeSupport ? 'secondary' : 'primary'}
										onClick={(): void => {
											shareLocationUrl(location)
										}}
									>
										<DirectionsIcon className="mr-1 h-6 w-auto" />
										<span>Directions</span>
									</Button>
								)

								if (hasQrCodeSupport) {
									return (
										<>
											{directionButton}
											<Button
												className="flex-1"
												onClick={(): void => {
													navigate({
														pathname: ScreenRoutePath.CheckInQrScanner,
														search: `cpoEntityCodes=${location.entity_code}`
													})
												}}
											>
												<QrScannerIcon className="mr-1 h-5 w-auto" />
												<span>Scan QR Code</span>
											</Button>
										</>
									)
								}
								return directionButton
							})()}
						</div>
					</div>
				)}
			</ScreenContainer>
			<CarparkRatesModal
				location={location}
				open={isCarparkModalOpen}
				onClose={handleCarParkRatesModalClose}
			/>
			<FavouriteErrorModal
				open={!!favouriteActionErrorMessage}
				onClose={handleCloseFavouriteErrorModal}
				message={favouriteActionErrorMessage}
			/>
		</>
	)
}

export default LocationDetailsScreen
