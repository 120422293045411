import { memo, MouseEvent, useCallback, useState } from 'react'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import CompassIcon from 'src/_shared/components/_icons/CompassIcon'
import { Coordinates } from 'src/_shared/types/location'
import { classNames } from 'src/_shared/utils/elements'

import { CURRENT_LOCATION_ZOOM } from '../constants'

interface UserLocationButtonProps {
	className?: string
	map?: google.maps.Map | null
	coordinates: Coordinates | null
	isLocationAccessGranted: boolean | null
}

const UserLocationButton = ({
	className,
	map,
	coordinates,
	isLocationAccessGranted
}: UserLocationButtonProps): JSX.Element => {
	const [isLocationAccessModalOpen, setIsLocationAccessModalOpen] = useState<boolean>(false)
	/**
	 * If permitted and possible, move to and zoom into the user's current position.
	 */
	const handleUserLocationClick = useCallback(
		(event: MouseEvent): void => {
			event.stopPropagation()
			event.preventDefault()

			if (coordinates && isLocationAccessGranted) {
				map?.panTo({ lng: coordinates.longitude, lat: coordinates.latitude })
				map?.setZoom(CURRENT_LOCATION_ZOOM)
			} else {
				setIsLocationAccessModalOpen(true)
			}
		},
		[coordinates, isLocationAccessGranted, map]
	)

	const handleLocationAccessModalClose = useCallback((): void => {
		setIsLocationAccessModalOpen(false)
	}, [])

	return (
		<>
			<Button
				className={classNames('rounded-full', className)}
				disabled={!!isLocationAccessGranted && !coordinates}
				onClick={handleUserLocationClick}
				variant="none"
			>
				<CompassIcon className="h-6 w-6 text-primary-800" />
			</Button>
			{/* Location Access Modal */}
			<Modal className="fixed" open={isLocationAccessModalOpen}>
				<ModalCard>
					<p className="body-2-light mb-8 text-center">
						Location services are required to determine your position. Please{' '}
						<span className="body-2-medium">enable location permissions</span> on your device.
					</p>
					<Button className="w-full" onClick={handleLocationAccessModalClose}>
						Close
					</Button>
				</ModalCard>
			</Modal>
		</>
	)
}

const MemoisedUserLocationButton = memo(UserLocationButton)

export default MemoisedUserLocationButton
