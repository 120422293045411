import { useCallback, useMemo } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import LocationFilterButton from 'src/_shared/components/LocationFilterButton'
import LocationFiltersModalWrapper from 'src/_shared/components/LocationFiltersModalWrapper'
import LocationSearchBar from 'src/_shared/components/LocationSearchBar'
import QuickFilterButtons from 'src/_shared/components/QuickFilterButtons'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import { USER_ZOOM_COORDINATES_KEY } from 'src/_shared/constants/storage'
import useSelectedPlaceSuggestion from 'src/_shared/hooks/useSelectedPlaceSuggestion'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { useUserSelectedLocation } from 'src/_shared/hooks/useUserSelectedLocation'
import {
	UserZoomCoordinates,
	useUserZoomCoordinates
} from 'src/_shared/hooks/useUserZoomCoordinates'
import { PlaceSuggestion } from 'src/_shared/types/google'
import { Coordinates } from 'src/_shared/types/location'
import LocationsListView from 'src/screens/LocationsListingScreen/components/LocationsListView'
import LocationsNearbyListView from 'src/screens/LocationsListingScreen/components/LocationsNearbyListView'

const LocationsListingScreen = (): JSX.Element => {
	const navigate = useRouterNavigate()

	const [selectedPlaceSuggestion] = useSelectedPlaceSuggestion()

	const { coordinates } = useUserCoordinates()

	const { setSelectedLocation } = useUserSelectedLocation()

	const { setUserZoomCoordinate } = useUserZoomCoordinates()

	const searchCoordinates = useMemo((): Coordinates | null => {
		// prioritize selectedPlaceSuggestion if there is any

		if (selectedPlaceSuggestion) {
			return selectedPlaceSuggestion.coordinates
		}

		return coordinates
	}, [coordinates, selectedPlaceSuggestion])

	const handlePlaceSuggestionClick = useCallback(
		(placeSuggestion: PlaceSuggestion): void => {
			setSelectedLocation(null)
			setUserZoomCoordinate('coordinates', {
				lng: placeSuggestion.coordinates.longitude.toString(),
				lat: placeSuggestion.coordinates.latitude.toString()
			})
		},
		[setSelectedLocation, setUserZoomCoordinate]
	)

	const handleBackClick = useCallback((): void => {
		try {
			const storedUserZoomCoordinates = sessionStorage.getItem(USER_ZOOM_COORDINATES_KEY)
			if (storedUserZoomCoordinates) {
				const parsedUserZoomCoordinates: unknown = JSON.parse(storedUserZoomCoordinates)
				if (parsedUserZoomCoordinates) {
					const { coordinates, zoom = DEFAULT_ZOOM } =
						parsedUserZoomCoordinates as UserZoomCoordinates
					const { lat = 0, lng = 0 } = coordinates ?? {}
					navigate({
						pathname: ScreenRoutePath.Map,
						search: `lat=${lat}&lng=${lng}&zoom=${zoom}`
					})
				}
			} else {
				navigate(ScreenRoutePath.Map)
			}
		} catch (error) {
			console.error('[LocationsListingScreen]', error)
		}
	}, [navigate])

	return (
		<LocationFiltersModalWrapper>
			<ScreenContainer
				contentViewProps={{
					className: 'relative'
				}}
				topBarProps={{
					centerRender: <h1>Charger List</h1>,
					leftRender: (
						<TopBarButton data-testid="hs-btn-back" onClick={handleBackClick}>
							<ArrowLeftIcon className="h-4" />
						</TopBarButton>
					),
					className: 'shadow-none'
				}}
			>
				{/* Search Bar Portion */}
				<LocationSearchBar
					className="pointer-events-none absolute top-0 z-10 h-full w-full px-2.5 pt-3 has-[input:focus]:bg-black/40 [&>form+div]:border [&>form+div]:border-t-0 [&>form+div]:border-input-boundary"
					endAdornment={<LocationFilterButton />}
					showCurrentLocationPlaceholder={true}
					onPlaceSuggestionClick={handlePlaceSuggestionClick}
				/>
				<div className="mt-20" />
				{/* Filter Buttons */}
				<QuickFilterButtons className="flex px-2.5 pb-3 shadow-md" />
				{/* Location List */}
				{searchCoordinates !== null ? (
					<LocationsNearbyListView coordinates={searchCoordinates} />
				) : (
					<LocationsListView />
				)}
			</ScreenContainer>
		</LocationFiltersModalWrapper>
	)
}

export default LocationsListingScreen
