import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { classNames } from 'src/_shared/utils/elements'

import BottomBar, { BottomBarProps } from './components/BottomBar'
import ContentView, { ContentViewProps } from './components/ContentView'
import TopBar, { TopBarProps } from './components/TopBar'

type ScreenContainerProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'ref'
> & {
	topBarProps?: TopBarProps
	contentViewProps?: Omit<ContentViewProps, 'children'>
	bottomBarProps?: BottomBarProps
	hideTopBar?: boolean
	hideBottomBar?: boolean
	disableScrollRestoration?: boolean
}

const ScreenContainer = ({
	children,
	className,
	topBarProps,
	contentViewProps,
	bottomBarProps,
	hideTopBar,
	hideBottomBar,
	disableScrollRestoration,
	style,
	...props
}: ScreenContainerProps): JSX.Element => {
	return (
		<>
			{!disableScrollRestoration && <ScrollRestoration />}
			<div
				className={classNames('mx-auto flex min-h-full max-w-112 flex-col', className)}
				style={{
					...style,
					paddingBottom:
						style?.paddingBottom ??
						(hideBottomBar ? 'calc(env(safe-area-inset-bottom))' : undefined)
				}}
				{...props}
			>
				{!hideTopBar && <TopBar {...topBarProps} />}
				<ContentView {...contentViewProps}>{children}</ContentView>
				{!hideBottomBar && <BottomBar {...bottomBarProps} />}
			</div>
		</>
	)
}

const MemoisedScreenContainer = memo(ScreenContainer)

export default MemoisedScreenContainer
