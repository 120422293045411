import { memo, useMemo } from 'react'
import Spinner from 'src/_shared/components/Spinner'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationsNearbyInfiniteQuery } from 'src/_shared/queries/locations'
import { useUserFavouriteLocationsSummaryQuery } from 'src/_shared/queries/user'
import { Coordinates } from 'src/_shared/types/location'
import { OmniLocationNearby } from 'src/_shared/types/omni/location'
import { classNames } from 'src/_shared/utils/elements'
import { filterLocationsWithAppliedFilters } from 'src/_shared/utils/filter'

import LocationListController from './LocationsListController'

const QUERY_RADIUS_MAX = 50000 // Metres

interface LocationsNearbyListViewProps {
	coordinates: Coordinates
}

const LocationsNearbyListView = ({ coordinates }: LocationsNearbyListViewProps): JSX.Element => {
	const { user } = useAuthContext()

	const { locationFilters } = useLocationFilters()

	const {
		data: locationsData = {
			pages: []
		},
		isLoading,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage
	} = useLocationsNearbyInfiniteQuery({
		latitude: coordinates.latitude,
		longitude: coordinates.longitude,
		radius: QUERY_RADIUS_MAX,
		entityCodes: locationFilters.cpoEntityCodes?.join(','),
		powerType: locationFilters.powerType,
		publish: true
	})

	const { data: favouriteLocations = [] } = useUserFavouriteLocationsSummaryQuery(
		{},
		{
			staleTime: Infinity, // Cache indefinitely
			refetchOnMount: 'always'
		}
	)

	const flattenFavouriteLocations = useMemo((): string[] => {
		return favouriteLocations.map((location) => location.locationUid)
	}, [favouriteLocations])

	const locations = useMemo((): OmniLocationNearby[] => {
		return locationsData.pages.reduce((acc: OmniLocationNearby[], locationPage) => {
			const flattenLocations = locationPage.data.reduce<OmniLocationNearby[]>(
				(locations, { location = {}, distance_meters: distanceMetres }): OmniLocationNearby[] => {
					if (
						!filterLocationsWithAppliedFilters(
							location,
							user?.subscribedCpoEntities,
							flattenFavouriteLocations,
							locationFilters
						)
					) {
						return locations
					}
					return locations.concat({ location, distance_meters: distanceMetres })
				},
				[]
			)
			return [...acc, ...flattenLocations]
		}, [])
	}, [flattenFavouriteLocations, locationFilters, locationsData.pages, user?.subscribedCpoEntities])

	return (
		<div
			className={classNames(
				'flex w-full flex-grow',
				isLoading || locations.length === 0 ? 'items-center justify-center' : null
			)}
		>
			{isLoading ? (
				<Spinner />
			) : locations.length === 0 ? (
				<p className="body-1-semibold text-typography-primary">No Locations Found</p>
			) : (
				<LocationListController
					locations={locations}
					isFetchingNextPage={isFetchingNextPage}
					onFetchNextPageCallback={hasNextPage ? fetchNextPage : undefined}
				/>
			)}
		</div>
	)
}

const MemoisedLocationsNearbyListView = memo(LocationsNearbyListView)

export default MemoisedLocationsNearbyListView
