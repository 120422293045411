import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryParams } from 'src/App/router/hooks'
import CarparkRatesModal from 'src/_shared/components/CarparkRatesModal'
import FavouriteErrorModal from 'src/_shared/components/FavouriteErrorModal'
import LiveSession from 'src/_shared/components/LiveSession'
import LocationFilterButton from 'src/_shared/components/LocationFilterButton'
import LocationFiltersModalWrapper from 'src/_shared/components/LocationFiltersModalWrapper'
import LocationSearchBar from 'src/_shared/components/LocationSearchBar'
import QuickFilterButtons from 'src/_shared/components/QuickFilterButtons'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import VoltalityLogoIcon from 'src/_shared/components/_icons/VoltalityLogoIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import useSelectedPlaceSuggestion from 'src/_shared/hooks/useSelectedPlaceSuggestion'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { useUserSelectedLocation } from 'src/_shared/hooks/useUserSelectedLocation'
import { useUserZoomCoordinates } from 'src/_shared/hooks/useUserZoomCoordinates'
import { useUserFavouriteLocationsSummaryQuery } from 'src/_shared/queries/user'
import { PlaceSuggestion } from 'src/_shared/types/google'
import { Coordinates } from 'src/_shared/types/location'
import { ClusterProperties, PointFeature } from 'supercluster'

import CurrentPositionMarker from './components/CurrentPositionMarker'
import LocationMarker from './components/LocationMarker'
import LocationModalCard from './components/LocationModalCard'
import LocationsClusterMarker from './components/LocationsClusterMarker'
import Map from './components/Map'
import SearchPositionMarker from './components/SearchPositionMarker'
import UserLocationButton from './components/UserLocationButton'
import { CURRENT_LOCATION_ZOOM } from './constants'
import { useLocationClusters } from './hooks'
import {
	GoogleApiProps,
	GoogleMapOptions,
	LocationPointDetails,
	MapScreenQueryParams
} from './types'
import { isPointFeatureCluster } from './utils'

const MapScreen = (): JSX.Element => {
	const [favouriteActionErrorMessage, setFavouriteActionErrorMessage] = useState<string | null>(
		null
	)

	const [isCarparkModalOpen, setCarparkModalOpen] = useState<boolean>(false)

	const [googleApiProps, setGoogleApiProps] = useState<GoogleApiProps | null>(null)

	const [mapOptions, setMapOptions] = useState<GoogleMapOptions | null>(null)

	const [{ lat, lng, zoom }, setSearchParams] = useQueryParams<MapScreenQueryParams>()

	const { selectedLocation, setSelectedLocation } = useUserSelectedLocation()

	const { setUserZoomCoordinates } = useUserZoomCoordinates()

	const mapCoordinates = useMemo((): {
		center: Coordinates
		northEast: Coordinates
		southWest: Coordinates
	} => {
		return {
			center: {
				latitude: mapOptions?.bounds.getCenter().lat() ?? 0,
				longitude: mapOptions?.bounds.getCenter().lng() ?? 0
			},
			northEast: {
				latitude: mapOptions?.bounds.getNorthEast().lat() ?? 0,
				longitude: mapOptions?.bounds.getNorthEast().lng() ?? 0
			},
			southWest: {
				latitude: mapOptions?.bounds.getSouthWest().lat() ?? 0,
				longitude: mapOptions?.bounds.getSouthWest().lng() ?? 0
			}
		}
	}, [mapOptions])

	const { pointFeatures, supercluster } = useLocationClusters({
		coordinates: mapCoordinates,
		enabled: !!mapOptions,
		zoom: mapOptions?.zoom ?? 0
	})

	const { data: favouriteLocations = [] } = useUserFavouriteLocationsSummaryQuery(
		{},
		{
			staleTime: Infinity, // Cache indefinitely
			refetchOnMount: 'always'
		}
	)

	const [selectedPlaceSuggestion] = useSelectedPlaceSuggestion()

	const { coordinates, isPermissionGranted: isLocationAccessGranted } = useUserCoordinates()

	const { map = null } = googleApiProps ?? {}

	const queryParametersLatLng = useMemo((): google.maps.LatLngLiteral | null => {
		if (
			[lat, lng].every((coordinate): boolean => !!coordinate && !!coordinate.match(/^\d*\.?\d*$/))
		) {
			return {
				lat: Number(lat),
				lng: Number(lng)
			}
		}
		return null
	}, [lat, lng])

	const [isFirstZoomExecuted, setIsFirstZoomExecuted] = useState<boolean>(
		// Do not trigger the first zoom if there were initial query parameters or a selected place suggestion.
		!!queryParametersLatLng || !!selectedPlaceSuggestion
	)

	const mapDefaultCenter = useMemo((): google.maps.LatLngLiteral | null => {
		// 1. Use coordinates provided via query parameters
		if (queryParametersLatLng) {
			return queryParametersLatLng
		}
		// 2. Use coordinates from selected place suggestion
		else if (selectedPlaceSuggestion) {
			return {
				lng: selectedPlaceSuggestion.coordinates.longitude,
				lat: selectedPlaceSuggestion.coordinates.latitude
			}
		}
		// 3. Use coordinates of user's current position
		else if (coordinates) {
			return {
				lng: coordinates.longitude,
				lat: coordinates.latitude
			}
		}
		return null
	}, [coordinates, queryParametersLatLng, selectedPlaceSuggestion])

	const mapDefaultZoom = useMemo((): number | null => {
		if (!isNaN(Number(zoom))) {
			return Number(zoom)
		}
		return null
	}, [zoom])

	const handleMapLoaded = useCallback((props: GoogleApiProps): void => {
		setGoogleApiProps(props)
	}, [])

	const handleMapChange = useCallback(
		(options: GoogleMapOptions): void => {
			setMapOptions(options)

			/**
			 * 7 digits are sufficient to store coordinates with centimeter accuracy and 8 digits are enough to store coordinates with millimeter accuracy.
			 * @see https://groups.google.com/g/lastools/c/fWl2gg7NKd4
			 */
			if (
				options.center.length === 2 &&
				options.center[0] !== undefined &&
				options.center[1] !== undefined
			) {
				const lng = options.center[0].toFixed(8)
				const lat = options.center[1].toFixed(8)
				const zoom = options.zoom.toString()
				setUserZoomCoordinates({
					coordinates: {
						lng,
						lat
					},
					zoom
				})
				setSearchParams({
					lat,
					lng,
					zoom
				})
			}
		},

		[setSearchParams, setUserZoomCoordinates]
	)

	const handleCloseLocationModalCard = useCallback((): void => {
		setSelectedLocation(null)
	}, [setSelectedLocation])

	const handleLocationsClusterMarkerClick = useCallback(
		(pointFeature: PointFeature<ClusterProperties>) => (): void => {
			const [lng, lat] = pointFeature.geometry.coordinates
			map?.panTo({ lng, lat })
			map?.setZoom((mapOptions?.zoom ?? DEFAULT_ZOOM) + 2)
		},
		[map, mapOptions?.zoom]
	)

	const handleLocationMarkerClick = useCallback(
		(pointFeature: PointFeature<LocationPointDetails>) => (): void => {
			const [lng, lat] = pointFeature.geometry.coordinates
			map?.panTo({ lng, lat })
			console.debug('[handleLocationMarkerClick]', pointFeature.properties.location)
			setSelectedLocation(pointFeature.properties.location)
		},
		[setSelectedLocation, map]
	)

	const pointFeatureElements = useMemo((): JSX.Element[] => {
		return Object.values(
			pointFeatures
				.map((pointFeature): JSX.Element => {
					const { id, geometry } = pointFeature

					const [lng, lat] = geometry.coordinates

					// Render Cluster Marker containing a group of Locations
					if (isPointFeatureCluster(pointFeature)) {
						const clusterPointFeatures = ((): PointFeature<LocationPointDetails>[] => {
							try {
								return supercluster?.getLeaves(pointFeature.properties.cluster_id, Infinity) ?? []
							} catch (error) {
								console.debug('[Supercluster]', error)
								return []
							}
						})()

						// Ensure the same key to avoid excessive re-rendering
						const clusterKey = clusterPointFeatures
							.map((pointFeature): string => {
								return pointFeature.properties.location.uid ?? ''
							})
							.sort()
							.join(',')

						const clusterHasAvailableConnector = clusterPointFeatures.some(
							(clusterPointFeature): boolean => {
								return clusterPointFeature.properties.hasAvailableConnector
							}
						)

						return (
							<LocationsClusterMarker
								key={clusterKey}
								lng={lng}
								lat={lat}
								zIndex={10}
								hasAvailableConnector={clusterHasAvailableConnector}
								locationsCount={pointFeature.properties.point_count}
								onClick={handleLocationsClusterMarkerClick(pointFeature)}
							/>
						)
					}

					const isLocationFavourite = favouriteLocations.some(
						(favouriteLocation): boolean => favouriteLocation.locationUid === id?.toString()
					)

					// Render Location Marker for a standalone Location
					return (
						<LocationMarker
							key={id}
							lng={lng}
							lat={lat}
							zIndex={10}
							hasAvailableConnector={pointFeature.properties.hasAvailableConnector}
							isLocationFavourite={isLocationFavourite}
							onClick={handleLocationMarkerClick(pointFeature)}
						/>
					)
				})
				// Prevent duplicate elements from being returned.
				.reduce<Record<string, JSX.Element>>(
					(keyToElementMap, element): Record<string, JSX.Element> => {
						if (element.key) {
							keyToElementMap[element.key] = element
						}
						return keyToElementMap
					},
					{}
				)
		)
	}, [
		favouriteLocations,
		pointFeatures,
		supercluster,
		handleLocationMarkerClick,
		handleLocationsClusterMarkerClick
	])

	const handlePlaceSuggestionClick = useCallback(
		(placeSuggestion: PlaceSuggestion): void => {
			map?.panTo({
				lng: placeSuggestion.coordinates.longitude,
				lat: placeSuggestion.coordinates.latitude
			})
			map?.setZoom(CURRENT_LOCATION_ZOOM)
			setSelectedLocation(null)
		},
		[map, setSelectedLocation]
	)

	const handleCarParkRatesModalOpen = useCallback((): void => {
		setCarparkModalOpen(true)
	}, [])

	const handleCarParkRatesModalClose = useCallback((): void => {
		setCarparkModalOpen(false)
	}, [])

	const handleCloseFavouriteErrorModal = useCallback((): void => {
		setFavouriteActionErrorMessage(null)
	}, [])

	const handleFavouriteActionError = useCallback((message: string): void => {
		setFavouriteActionErrorMessage(message)
	}, [])

	/**
	 * If possible, zoom into the user's current location when they first load up the map.
	 */
	useEffect((): void => {
		if (!!map && coordinates && !isFirstZoomExecuted) {
			map.panTo({ lng: coordinates.longitude, lat: coordinates.latitude })
			map.setZoom(CURRENT_LOCATION_ZOOM)
			setIsFirstZoomExecuted(true)
		}
	}, [coordinates, isFirstZoomExecuted, map, queryParametersLatLng, selectedPlaceSuggestion, zoom])

	return (
		<LocationFiltersModalWrapper>
			<ScreenContainer
				contentViewProps={{
					className: 'relative'
				}}
				topBarProps={{
					// To hide the back button
					leftRender: <></>
				}}
			>
				{/* Overlay Elements */}
				<LocationSearchBar
					className="pointer-events-none absolute top-0 z-40 h-full w-full px-2.5 pt-3 has-[input:focus]:bg-black has-[input:focus]:bg-opacity-40 [&>form>div]:border-transparent [&>form>div]:shadow-md"
					onPlaceSuggestionClick={handlePlaceSuggestionClick}
					endAdornment={
						<div className="flex">
							{!selectedPlaceSuggestion && (
								<UserLocationButton
									className="mr-2.5"
									map={map}
									coordinates={coordinates}
									isLocationAccessGranted={isLocationAccessGranted}
								/>
							)}
							<LocationFilterButton />
						</div>
					}
				/>
				{/* Filter Buttons */}
				<QuickFilterButtons className="absolute top-20 z-10 w-full px-2.5" />
				<LiveSession dataTestIdPrefix="ms" className="absolute top-32 z-10 w-full px-2.5" />
				{selectedLocation && (
					<div className="absolute bottom-8 z-30 w-full px-2.5 py-3">
						<LocationModalCard
							location={selectedLocation}
							coordinates={coordinates}
							onClose={handleCloseLocationModalCard}
							handleCarParkRatesModalOpen={handleCarParkRatesModalOpen}
							handleFavouriteActionError={handleFavouriteActionError}
						/>
					</div>
				)}
				<VoltalityLogoIcon className="pointer-events-none absolute bottom-7 left-2.5 z-10 w-20" />
				{/* Map with Map Markers */}
				<Map
					defaultCenter={mapDefaultCenter}
					defaultZoom={mapDefaultZoom}
					onChange={handleMapChange}
					onLoaded={handleMapLoaded}
				>
					{/* Point Features */}
					{pointFeatureElements}
					{/* User's Current Position */}
					{coordinates && (
						<CurrentPositionMarker
							zIndex={20}
							lng={coordinates.longitude}
							lat={coordinates.latitude}
						/>
					)}
					{/* Selected Place Suggestion Position */}
					{selectedPlaceSuggestion && (
						<SearchPositionMarker
							zIndex={30}
							lng={selectedPlaceSuggestion.coordinates.longitude}
							lat={selectedPlaceSuggestion.coordinates.latitude}
						/>
					)}
				</Map>
			</ScreenContainer>
			<CarparkRatesModal
				location={selectedLocation}
				open={isCarparkModalOpen}
				onClose={handleCarParkRatesModalClose}
			/>
			<FavouriteErrorModal
				open={!!favouriteActionErrorMessage}
				onClose={handleCloseFavouriteErrorModal}
				message={favouriteActionErrorMessage}
			/>
		</LocationFiltersModalWrapper>
	)
}

export default MapScreen
