import { memo, useMemo } from 'react'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { ConnectorsGroupedByPower, PowerToConnectorsGroupMap } from 'src/_shared/types/charging'
import { OmniEvse } from 'src/_shared/types/omni'
import { getAvailableConnectorsCount, sortEvsesByPower } from 'src/_shared/utils/charging'
import { classNames } from 'src/_shared/utils/elements'

import ConnectorRatingBox from './components/ConnectorRatingBox'

interface ConnectorRatingBoxesProps {
	className?: string
	connectorRatingBoxClassName?: string
	evses?: OmniEvse[]
	enableFilter?: boolean
}

const ConnectorRatingBoxes = ({
	className,
	connectorRatingBoxClassName,
	evses = [],
	enableFilter = true
}: ConnectorRatingBoxesProps): JSX.Element => {
	const { locationFilters } = useLocationFilters()

	const powerFilter = locationFilters.powerType

	const groupedConnectors = useMemo((): PowerToConnectorsGroupMap => {
		return sortEvsesByPower(evses)
	}, [evses])

	return (
		<div className={classNames(className)}>
			{Array.from(Object.entries(groupedConnectors)).map(
				([key, value]: [string, ConnectorsGroupedByPower]): JSX.Element | null => {
					if (enableFilter && powerFilter !== null && powerFilter !== value.powerType) {
						return null
					}

					const { availableCount, totalCount } = getAvailableConnectorsCount(value.connectors)
					return (
						<ConnectorRatingBox
							key={key}
							className={connectorRatingBoxClassName}
							powerType={value.powerType}
							powerRating={value.powerRating}
							availableCount={availableCount}
							totalCount={totalCount}
						/>
					)
				}
			)}
		</div>
	)
}

const MemoisedConnectorRatingsBoxes = memo(ConnectorRatingBoxes)

export default MemoisedConnectorRatingsBoxes
